<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>账户设置</span>
    </div>
    <div class="content-fa">
      <div class="main-content">
        <div class="line-box">
          <div class="left">手机号</div>
          <div class="right">
            <span>{{ userInfo.mobile }}</span>
            <img src="@/assets/image/arrow-right-grey.png" />
          </div>
        </div>
        <div class="line"></div>
        <div class="line-box">
          <div class="left">电子邮箱</div>
          <div class="right">
            <span>{{ userInfo.email }}</span>
            <img src="@/assets/image/arrow-right-grey.png" />
          </div>
        </div>
        <div class="line"></div>
        <div class="line-box" @click="goUrl('/mobile/alipay')">
          <div class="left">支付宝账户</div>
          <div class="right">
            <span
              class="grey"
              v-if="!userInfo.alipay || userInfo.alipay === '-'"
              >未绑定</span
            >
            <span v-else>{{ userInfo.alipay }}</span>
            <img src="@/assets/image/arrow-right-grey.png" />
          </div>
        </div>
        <div class="line"></div>
        <div class="line-box" @click="goUrl('/mobile/changePassword')">
          <div class="left">修改密码</div>
          <div class="right">
            <img src="@/assets/image/arrow-right-grey.png" />
          </div>
        </div>
      </div>
      <div class="btn2" @click="LogOut">退出登录</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: null
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    LogOut() {
      localStorage.clear();
      this.$router.push("/mobile");
    },
    goUrl(path) {
      this.$router.push(path);
    }
  },
  async created() {
    if (!localStorage.getItem("userInfo")) {
      if (localStorage.getItem("userid")) {
        let userid = localStorage.getItem("userid");
        const res = await this.$http.get(`/api/v1/Accounts/${userid}`, {
          params: {
            id: userid
          }
        });
        if (res.status === 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        }
      } else {
        this.$router.push("/mobile");
      }
    }
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("11111", this.userInfo);
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #fff;
    img {
      width: 20px;
      position: absolute;
      left: 0;
      margin-left: 15px;
    }
  }
  .main-content {
    background-color: #fff;
    padding: 0 15px;
    box-sizing: border-box;
    width: 100%;
    .line-box {
      display: flex;
      justify-content: space-between;
      height: 50px;
      align-items: center;
      .left {
        color: #232323;
        font-size: 14px;
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 14px;
          margin-left: 8px;
        }
        font-size: 14px;
        color: #232323;
        .grey {
          color: #808080;
        }
      }
    }
    .line {
      width: 100%;
      height: 0px;
      border: 1px solid #f4f4f4;
    }
  }
  .content-fa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 40px);
    .btn2 {
      width: calc(100% - 30px);
      height: 46px;
      border-radius: 25px 25px 25px 25px;
      text-align: center;
      line-height: 46px;
      color: #ff6017;
      font-size: 18px;
      border: 1px solid #ff6017;
      box-sizing: border-box;
      margin: 40px 15px;
    }
  }
}
</style>
